<template>
    <BaseSection>
        <template #title>
            <h2 v-show="title && hasMainTitle">{{ title }}</h2>
        </template>
        <template #description>
            <p v-show="description">{{ description }}</p>
        </template>
        <template #default>
            <BaseAccordion
                :items="accordionItems"
                @click="handleAccordionClick(title)"
            >
                <template #title="{ item }">
                    {{ item.title }}
                </template>
                <template #default="{ item }">
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        data-allow-anchor
                        v-html="item.content"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                </template>
            </BaseAccordion>
        </template>
    </BaseSection>
</template>

<script setup>
const props = defineProps({
    title: {
        type: String,
        required: false,
        default: null,
    },
    description: {
        type: String,
        required: false,
        default: null,
    },
    items: {
        type: Array,
        required: false,
        default: null,
    },
});

const accordionItems = computed(() => {
    const fallbackTitle = props.items?.length === 1 ? props.title : null;

    return props.items.map((item) => {
        return {
            title: item?.blockTitle ?? fallbackTitle,
            content: item?.richText ?? item?.transcript,
        };
    });
});

const hasMainTitle = computed(() => props.items?.length > 1);

const { trackEvent } = useCustomGtmEvent();
const pageType = inject('pageType')?.pageType;

const handleAccordionClick = (title) => {
    trackEvent({
        event: 'accordion_click',
        'page_type': pageType?.value,
        'accordion_text': title
    });
};
</script>
