<template>
    <figure class="video">
        <video
            class="video__player"
            controls
            :src="videoUrl"
        />
        <figcaption v-if="videoCaption">
            {{ videoCaption }}
        </figcaption>
    </figure>
</template>

<script setup>
const props = defineProps({
    videoUrl: {
        type: String,
        required: true,
    },
    videoCaption: {
        type: String,
        required: false,
    },
});

const pageType = inject('pageType')?.pageType;
const { trackEvent } = useCustomGtmEvent();

onMounted(() => {
    const video = document.querySelector('.video__player');

    const playListener = video.addEventListener('play', () => {
        trackEvent({
            event: 'video_play',
            'page_type': pageType?.value ?? null,
            'video_url': props.videoUrl
        });

        video.removeEventListener('play', playListener);
    });

    const completionListener = video.addEventListener('ended', () => {
        trackEvent({
            event: 'video_completion',
            'page_type': pageType?.value ?? null,
            'video_url': props.videoUrl
        });

        video.removeEventListener('ended', completionListener);
    });
});
</script>

<style lang="less" scoped>
.video {
    width: 100%;
    max-width: 100%;
    margin: 0 0 var(--spacing-xl);
}

.video__player {
    width: 100%;
    height: auto;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    border-radius: var(--borderRadius-sm);
    outline: none;

    @media @q-lg-min {
        border-radius: var(--borderRadius-xs);
        transition: box-shadow 0.3s ease;
        box-shadow: 0 0 1rem transparent;

        max-width: var(--desktop-video-width, 70%);
        margin: 0 auto var(--spacing-xl);

        &:hover {
            box-shadow: 0 0 1rem transparent;
            cursor: pointer;
        }
    }

}

</style>
